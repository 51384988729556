import React from "react";
import { NavLink } from "react-router-dom";

const Item = ({ data, index, round }) => {
  return (
    <NavLink
      to={(data.page && data.page.url) || data.url}
      data-uk-toggle="#mobile"
      style={{
        padding: `20px 25px 20px ${25 + round * 25}px`,
        color: "#333",
        backgroundColor:
          round === 0 && index % 2 === 0
            ? "#F3F3F3"
            : round === 2
            ? "rgb(217, 231, 237)"
            : "#E9E9E9",
      }}
    >
      {data.label}
    </NavLink>
  );
};

const ItemSelector = ({ data, index, round }) => {
  return (
    <li className={data.children.length > 0 ? "uk-parent" : ""}>
      {data.children.length > 0 ? (
        <>
          <a
            href="#"
            style={{
              padding: `20px 25px 20px ${25 + round * 25}px`,
              color: "#333",
              backgroundColor:
                round === 0 && index % 2 === 0
                  ? "#F3F3F3"
                  : round === 2
                  ? "rgb(217, 231, 237)"
                  : "#E9E9E9",
            }}
          >
            {data.label}
          </a>
          <ul className="uk-nav-default uk-nav-parent-icon" data-uk-nav="">
            {data.url ||
              (data.page && data.page.url && (
                <li>
                  <NavLink
                    to={(data.page && data.page.url) || data.url}
                    style={{
                      padding: `20px 25px 20px ${25 + (round + 1) * 25}px`,
                      color: "#333",
                      backgroundColor:
                        round + 1 === 0 && index % 2 === 0
                          ? "#F3F3F3"
                          : round + 1 === 2
                          ? "rgb(217, 231, 237)"
                          : "#E9E9E9",
                    }}
                    data-uk-toggle="#mobile"
                  >
                    {data.label} Übersicht
                  </NavLink>
                </li>
              ))}
            {data.children.map((child, i) => (
              <ItemSelector
                key={child._id}
                data={child}
                index={index + i + 1}
                round={round + 1}
              />
            ))}
          </ul>
        </>
      ) : (
        <Item data={data} index={index} round={round} />
      )}
    </li>
  );
};

function SubNavMobile({ list = [], item }) {
  return (
    <ul
      className="uk-nav-default uk-nav-parent-icon uk-text-bold"
      data-uk-nav=""
    >
      {item.url ||
        (item.page && item.page.url && (
          <li>
            <NavLink
              to={(item.page && item.page.url) || item.url}
              style={{
                padding: "20px 25px",
                color: "#333",
                backgroundColor: "#E9E9E9",
              }}
              data-uk-toggle="#mobile"
            >
              {item.label} Übersicht
            </NavLink>
          </li>
        ))}
      {list.map((child, index) => (
        <ItemSelector key={child._id} data={child} index={index} round={0} />
      ))}
    </ul>
  );
}

export default SubNavMobile;
